import { ContentFormState } from "@/content/form/util/contentFormUtil"
import { useFormStore } from "@/core/form/store/FormStore"
import SubmitAssignmentFormFields, {
  SubmitAssignmentFormFieldsSkeleton,
} from "@/product/course/assignments/submission/SubmitAssignmentFormFields"
import {
  EditAssignmentSubmissionFormMutation,
  EditAssignmentSubmissionInput,
} from "@/product/course/assignments/submission/edit/__generated__/EditAssignmentSubmissionFormMutation.graphql"
import { EditAssignmentSubmissionFormQuery } from "@/product/course/assignments/submission/edit/__generated__/EditAssignmentSubmissionFormQuery.graphql"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import Form from "@components/form/Form"
import { displaySuccessToast } from "@components/toast/ToastProvider"
import { DiscoButton } from "@disco-ui"
import { observer } from "mobx-react-lite"
import { useLazyLoadQuery } from "react-relay"
import { graphql } from "relay-runtime"

interface Props {
  assignmentSubmissionId: GlobalID
  onSave: () => void
  onCancel: () => void
}

const EditAssignmentSubmissionForm = observer<Props>((props) => {
  const { assignmentSubmissionId, onSave, onCancel } = props

  const { assignmentSubmission } = useLazyLoadQuery<EditAssignmentSubmissionFormQuery>(
    graphql`
      query EditAssignmentSubmissionFormQuery($id: ID!) {
        assignmentSubmission: node(id: $id) {
          ... on AssignmentSubmission {
            id
            ...EditAssignmentSubmissionFormFragment @relay(mask: false)
            contentUsage {
              ...SubmitAssignmentFormFieldsFragment
            }
          }
        }
      }
    `,
    { id: assignmentSubmissionId },
    { fetchPolicy: "store-or-network" }
  )

  const form = useFormStore<
    EditAssignmentSubmissionFormMutation,
    EditAssignmentSubmissionInput & ContentFormState
  >(
    graphql`
      mutation EditAssignmentSubmissionFormMutation(
        $input: EditAssignmentSubmissionInput!
      ) {
        response: editAssignmentSubmission(input: $input) {
          node {
            id
            ...EditAssignmentSubmissionFormFragment
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      assignmentSubmissionId,
      content: {
        coverPhoto: assignmentSubmission?.coverPhoto,
        coverVideo: assignmentSubmission?.coverVideo,
        attachments: Relay.connectionToArray(assignmentSubmission?.content?.attachments),
        richEditorDescriptionContent: assignmentSubmission?.richEditorDescriptionContent,
        submissionLink: assignmentSubmission?.submissionLink,
      },
    }
  )

  if (!assignmentSubmission?.contentUsage) return null

  return (
    <Form
      testid={"EditAssignmentSubmissionForm.form"}
      onSubmit={handleSubmit}
      buttons={
        <>
          <DiscoButton onClick={onCancel} color={"grey"} variant={"outlined"}>
            {"Cancel"}
          </DiscoButton>
          <DiscoButton type={"submit"} shouldDisplaySpinner={form.isSubmitting}>
            {"Submit"}
          </DiscoButton>
        </>
      }
    >
      <SubmitAssignmentFormFields
        form={form}
        contentUsageKey={assignmentSubmission.contentUsage}
      />
    </Form>
  )

  async function handleSubmit() {
    const { didSave } = await form.submit({
      assignmentSubmissionId,
      content: form.state.content,
    })
    if (didSave) {
      displaySuccessToast({
        message: "Submission updated!",
        testid: "EditAssignmentSubmissionForm.success-toast",
      })
      onSave()
    }
  }
})

export default Relay.withSkeleton<Props>({
  component: EditAssignmentSubmissionForm,
  skeleton: SubmitAssignmentFormFieldsSkeleton,
})

// eslint-disable-next-line
graphql`
  fragment EditAssignmentSubmissionFormFragment on AssignmentSubmission {
    coverPhoto
    coverVideo
    coverVideoDurationSeconds
    richEditorDescriptionContent
    submissionLink
    content {
      attachments {
        edges {
          node {
            id
            name
            mediaUrl
          }
        }
      }
    }
  }
`
