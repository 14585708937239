/**
 * @generated SignedSource<<6210a33066abeb5c0bb0ff2037b50e5f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type EventVisibility = "cohort" | "member_group" | "public" | "%future added value";
export type OccurrenceMediaKind = "video" | "image" | "%future added value";
export type OccurrenceStatus = "draft" | "published" | "%future added value";
export type ProductStatus = "draft" | "published" | "archived" | "unlisted" | "%future added value";
export type ProductType = "course" | "membership_plan" | "community_event" | "pathway" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type OccurrenceListItemFragment$data = {
  readonly id: string;
  readonly name: string | null;
  readonly datetimeRange: ReadonlyArray<string>;
  readonly coverPhoto: string | null;
  readonly status: OccurrenceStatus;
  readonly isComplete: boolean;
  readonly visibility: EventVisibility;
  readonly appId: string | null;
  readonly hosts: {
    readonly totalCount: number;
  };
  readonly product: {
    readonly id: string;
    readonly cover: string;
    readonly type: ProductType;
    readonly name: string;
    readonly slug: string;
    readonly status: ProductStatus;
  };
  readonly event: {
    readonly id: string;
    readonly memberGroups: {
      readonly totalCount: number;
    };
    readonly " $fragmentSpreads": FragmentRefs<"MemberGroupEventTagListFragment" | "usePermissionsFragment">;
  };
  readonly content: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"BookmarkButtonContentFragment">;
  };
  readonly guestReport: {
    readonly totalCount: number;
  };
  readonly rsvps: {
    readonly totalCount: number;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly user: {
          readonly fullName: string;
        };
      };
    }>;
  } | null;
  readonly media: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly url: string;
        readonly kind: OccurrenceMediaKind;
      };
    }>;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"OccurrenceNameAndTimeFragment" | "OccurrenceRSVPAttendanceStatusFragment" | "OccurrenceStatusChipFragment" | "OccurrenceWatchVideoButtonFragment" | "DeleteOccurrenceButtonFragment">;
  readonly " $fragmentType": "OccurrenceListItemFragment";
};
export type OccurrenceListItemFragment$key = {
  readonly " $data"?: OccurrenceListItemFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"OccurrenceListItemFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v4 = [
  (v3/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OccurrenceListItemFragment",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "datetimeRange",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "coverPhoto",
      "storageKey": null
    },
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isComplete",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "visibility",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "appId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OccurrenceHostNodeConnection",
      "kind": "LinkedField",
      "name": "hosts",
      "plural": false,
      "selections": (v4/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Product",
      "kind": "LinkedField",
      "name": "product",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "cover",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slug",
          "storageKey": null
        },
        (v2/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Event",
      "kind": "LinkedField",
      "name": "event",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "MemberGroupEventTagListFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "usePermissionsFragment"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "MemberGroupNodeConnection",
          "kind": "LinkedField",
          "name": "memberGroups",
          "plural": false,
          "selections": (v4/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Content",
      "kind": "LinkedField",
      "name": "content",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "BookmarkButtonContentFragment"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OccurrenceNameAndTimeFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OccurrenceRSVPAttendanceStatusFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OccurrenceStatusChipFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OccurrenceWatchVideoButtonFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DeleteOccurrenceButtonFragment"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserMembershipNodeConnection",
      "kind": "LinkedField",
      "name": "guestReport",
      "plural": false,
      "selections": (v4/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 3
        }
      ],
      "concreteType": "OccurrenceRSVPNodeConnection",
      "kind": "LinkedField",
      "name": "rsvps",
      "plural": false,
      "selections": [
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "OccurrenceRSVPNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "OccurrenceRSVP",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "User",
                  "kind": "LinkedField",
                  "name": "user",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "fullName",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "rsvps(first:3)"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 1
        }
      ],
      "concreteType": "OccurrenceMediaNodeConnection",
      "kind": "LinkedField",
      "name": "media",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "OccurrenceMediaNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "OccurrenceMedia",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "url",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "kind",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "media(first:1)"
    }
  ],
  "type": "Occurrence",
  "abstractKey": null
};
})();

(node as any).hash = "89b2598f5ab43f011fa0b10ec3843ea2";

export default node;
