/**
 * @generated SignedSource<<86b9c72c506618d316a8150aaff668da>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteAssignmentSubmissionInput = {
  assignmentSubmissionId: string;
};
export type DeleteAssignmentSubmissionButtonMutation$variables = {
  input: DeleteAssignmentSubmissionInput;
};
export type DeleteAssignmentSubmissionButtonMutation$data = {
  readonly response: {
    readonly node: {
      readonly id: string;
      readonly contentUsage: {
        readonly id: string;
        readonly assignmentSubmissions: {
          readonly totalCount: number;
        };
      };
    } | null;
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
  };
};
export type DeleteAssignmentSubmissionButtonMutation = {
  variables: DeleteAssignmentSubmissionButtonMutation$variables;
  response: DeleteAssignmentSubmissionButtonMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "ContentUsage",
  "kind": "LinkedField",
  "name": "contentUsage",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "AssignmentSubmissionNodeConnection",
      "kind": "LinkedField",
      "name": "assignmentSubmissions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "ValidationError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "field",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteAssignmentSubmissionButtonMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v1/*: any*/),
        "concreteType": "DeleteAssignmentSubmissionResponse",
        "kind": "LinkedField",
        "name": "deleteAssignmentSubmission",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AssignmentSubmission",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteAssignmentSubmissionButtonMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v1/*: any*/),
        "concreteType": "DeleteAssignmentSubmissionResponse",
        "kind": "LinkedField",
        "name": "deleteAssignmentSubmission",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AssignmentSubmission",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "filters": null,
                "handle": "deleteRecord",
                "key": "",
                "kind": "ScalarHandle",
                "name": "id"
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "42ed44d45434e4da5e2a6146dc6b2de5",
    "id": null,
    "metadata": {},
    "name": "DeleteAssignmentSubmissionButtonMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteAssignmentSubmissionButtonMutation(\n  $input: DeleteAssignmentSubmissionInput!\n) {\n  response: deleteAssignmentSubmission(input: $input) {\n    node {\n      id\n      contentUsage {\n        id\n        assignmentSubmissions {\n          totalCount\n        }\n      }\n    }\n    errors {\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ab0f7813934fd27fb33b99997361e75d";

export default node;
