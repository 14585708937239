/**
 * @generated SignedSource<<2ecb63dbc5e7b409f96f4c193d6c4871>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditAssignmentSubmissionFormQuery$variables = {
  id: string;
};
export type EditAssignmentSubmissionFormQuery$data = {
  readonly assignmentSubmission: {
    readonly id?: string;
    readonly coverPhoto?: string | null;
    readonly coverVideo?: string | null;
    readonly coverVideoDurationSeconds?: number | null;
    readonly richEditorDescriptionContent?: string | null;
    readonly submissionLink?: string | null;
    readonly content?: {
      readonly attachments: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
            readonly name: string;
            readonly mediaUrl: string | null;
          };
        }>;
      };
    };
    readonly contentUsage?: {
      readonly " $fragmentSpreads": FragmentRefs<"SubmitAssignmentFormFieldsFragment">;
    };
  } | null;
};
export type EditAssignmentSubmissionFormQuery = {
  variables: EditAssignmentSubmissionFormQuery$variables;
  response: EditAssignmentSubmissionFormQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "coverPhoto",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "coverVideo",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "coverVideoDurationSeconds",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "richEditorDescriptionContent",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "submissionLink",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "ContentAttachmentNodeConnection",
  "kind": "LinkedField",
  "name": "attachments",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ContentAttachmentNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ContentAttachment",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "mediaUrl",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "enabled",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "required",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditAssignmentSubmissionFormQuery",
    "selections": [
      {
        "alias": "assignmentSubmission",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Content",
                "kind": "LinkedField",
                "name": "content",
                "plural": false,
                "selections": [
                  (v8/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ContentUsage",
                "kind": "LinkedField",
                "name": "contentUsage",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "SubmitAssignmentFormFieldsFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "AssignmentSubmission",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditAssignmentSubmissionFormQuery",
    "selections": [
      {
        "alias": "assignmentSubmission",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Content",
                "kind": "LinkedField",
                "name": "content",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ContentUsage",
                "kind": "LinkedField",
                "name": "contentUsage",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "submissionVisibility",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AssignmentSubmissionFormat",
                    "kind": "LinkedField",
                    "name": "submissionFormat",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AssignmentSubmissionFormatSettings",
                        "kind": "LinkedField",
                        "name": "text",
                        "plural": false,
                        "selections": (v9/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AssignmentSubmissionFormatSettings",
                        "kind": "LinkedField",
                        "name": "file",
                        "plural": false,
                        "selections": (v9/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AssignmentSubmissionFormatSettings",
                        "kind": "LinkedField",
                        "name": "link",
                        "plural": false,
                        "selections": (v9/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "AssignmentSubmission",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c38c88d9f53b3adc707b147d40f8d414",
    "id": null,
    "metadata": {},
    "name": "EditAssignmentSubmissionFormQuery",
    "operationKind": "query",
    "text": "query EditAssignmentSubmissionFormQuery(\n  $id: ID!\n) {\n  assignmentSubmission: node(id: $id) {\n    __typename\n    ... on AssignmentSubmission {\n      id\n      coverPhoto\n      coverVideo\n      coverVideoDurationSeconds\n      richEditorDescriptionContent\n      submissionLink\n      content {\n        attachments {\n          edges {\n            node {\n              id\n              name\n              mediaUrl\n            }\n          }\n        }\n        id\n      }\n      contentUsage {\n        ...SubmitAssignmentFormFieldsFragment\n        id\n      }\n    }\n    id\n  }\n}\n\nfragment SubmitAssignmentFormFieldsFragment on ContentUsage {\n  id\n  submissionVisibility\n  submissionFormat {\n    text {\n      enabled\n      required\n    }\n    file {\n      enabled\n      required\n    }\n    link {\n      enabled\n      required\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f7141c05fdc9c149781ba9ff7b1e3c43";

export default node;
