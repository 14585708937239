import CommentsSection, { CommentsSectionSkeleton } from "@/comments/CommentsSection"
import { CommentsSectionProvider } from "@/comments/CommentsSectionContext"
import CommentEditor from "@/comments/editor/CommentEditor"
import CommentUtils from "@/comments/utils/CommentUtils"
import { AssignmentSubmissionDetailsCommentsFragment$key } from "@/product/course/curriculum/section/drawer/items/assignment/tabs/__generated__/AssignmentSubmissionDetailsCommentsFragment.graphql"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import ScrollShadowContainer from "@components/scroll-shadow/ScrollShadowContainer"
import DiscoDrawerSidebar from "@disco-ui/drawer/DiscoDrawerSidebar"
import { TestIDProps } from "@utils/typeUtils"
import { graphql, useFragment } from "react-relay"

interface AssignmentSubmissionDetailsCommentsProps extends TestIDProps {
  submissionKey: AssignmentSubmissionDetailsCommentsFragment$key
}

function AssignmentSubmissionDetailsComments({
  submissionKey,
}: AssignmentSubmissionDetailsCommentsProps) {
  const classes = useStyles()

  const submission = useFragment<AssignmentSubmissionDetailsCommentsFragment$key>(
    graphql`
      fragment AssignmentSubmissionDetailsCommentsFragment on AssignmentSubmission {
        id
        contentId
        content {
          totalComments
        }
      }
    `,
    submissionKey
  )

  return (
    <DiscoDrawerSidebar classes={{ sidebar: classes.sidebar }}>
      <div className={classes.container}>
        <CommentsSectionProvider>
          <ScrollShadowContainer classes={{ scrollContainer: classes.commentsContainer }}>
            <CommentsSection
              testid={"AssignmentSubmissionDetails.comment-section"}
              contentId={submission.contentId}
              headerVariant={"body-md-600"}
            />
          </ScrollShadowContainer>
          <div className={classes.commentFooter}>
            <CommentEditor
              // Key used to reset and clear the RichTextEditor in CommentEditor when a comment is posted
              // contentId helps keep each editor unique to the content the comment is submitted for
              key={`comment-editor-${submission.contentId}-${submission.content.totalComments}`}
              testid={"AssignmentSubmissionDetails.comment-section"}
              contentId={submission.contentId}
              onCommentSubmitted={CommentUtils.scrollToNewComment}
              enforceMaxHeight
            />
          </div>
        </CommentsSectionProvider>
      </div>
    </DiscoDrawerSidebar>
  )
}

const useStyles = makeUseStyles((theme) => ({
  sidebar: {
    backgroundColor: theme.palette.background.paper,
    zIndex: theme.zIndex.raise2,
    width: "400px",
    overflow: "auto",

    [theme.breakpoints.down("md")]: {
      width: "300px",
    },
  },
  container: {
    display: "grid",
    gridTemplateRows: "1fr max-content",
    height: "100%",
  },
  commentsContainer: {
    padding: theme.spacing(1.5, 2, 2, 2),
    overflow: "auto",
  },
  commentFooter: {
    borderTop: theme.palette.constants.borderSmall,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(0, 1.5, 1.5, 1.5),
  },
}))

export function AssignmentSubmissionDetailsCommentsSkeleton() {
  const classes = useStyles()
  return (
    <DiscoDrawerSidebar classes={{ sidebar: classes.sidebar }}>
      <div className={classes.container}>
        <div className={classes.commentsContainer}>
          <CommentsSectionSkeleton headerVariant={"body-md-600"} />
        </div>
      </div>
    </DiscoDrawerSidebar>
  )
}

export default Relay.withSkeleton({
  component: AssignmentSubmissionDetailsComments,
  skeleton: AssignmentSubmissionDetailsCommentsSkeleton,
})
