/**
 * @generated SignedSource<<691a0ac392e59f114827e82fa252c24f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContentNotificationEmailCtaButton = "enabled" | "disabled" | "%future added value";
export type WebFormQuestionType = "single_select" | "multiple_select" | "written_answer" | "link" | "rating" | "ranking" | "%future added value";
export type EditAssignmentSubmissionInput = {
  assignmentSubmissionId: string;
  content: EditContentInput;
};
export type EditContentInput = {
  attachments?: ReadonlyArray<ContentAttachmentInput> | null;
  name?: string | null;
  description?: string | null;
  coverPhoto?: string | null;
  coverPhotoAssetId?: string | null;
  coverVideo?: string | null;
  coverVideoAssetId?: string | null;
  notificationEmailSubject?: string | null;
  richEditorNotificationEmailBodyContent?: string | null;
  sendNotificationEmail?: boolean | null;
  coverVideoDurationSeconds?: number | null;
  showComments?: boolean | null;
  showCoverPlayIcon?: boolean | null;
  coverAudio?: string | null;
  coverAudioAssetId?: string | null;
  thumbnailUrl?: string | null;
  thumbnailAssetId?: string | null;
  richEditorDescriptionContent?: string | null;
  releaseDatetime?: string | null;
  releaseNow?: boolean | null;
  notificationEmailCtaButton?: ContentNotificationEmailCtaButton | null;
  ctaText?: string | null;
  contentLabelId?: string | null;
  webFormRevision?: CreateWebFormRevisionInput | null;
  submissionLink?: string | null;
  isAiGenerated?: boolean | null;
};
export type ContentAttachmentInput = {
  id?: string | null;
  name?: string | null;
  mediaUrl?: string | null;
  mediaAssetId?: string | null;
};
export type CreateWebFormRevisionInput = {
  webFormId: string;
  contentUsageId?: string | null;
  questions: ReadonlyArray<WebFormQuestionInput>;
};
export type WebFormQuestionInput = {
  type: WebFormQuestionType;
  richEditorBody: string;
  options?: ReadonlyArray<WebFormQuestionOptionInput> | null;
  answerKey?: WebFormQuestionAnswerKeyInput | null;
  isRequired?: boolean | null;
};
export type WebFormQuestionOptionInput = {
  id: string;
  label: string;
};
export type WebFormQuestionAnswerKeyInput = {
  correctIds: ReadonlyArray<string>;
  feedback?: string | null;
};
export type EditAssignmentSubmissionFormMutation$variables = {
  input: EditAssignmentSubmissionInput;
};
export type EditAssignmentSubmissionFormMutation$data = {
  readonly response: {
    readonly node: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"EditAssignmentSubmissionFormFragment">;
    } | null;
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
  };
};
export type EditAssignmentSubmissionFormMutation = {
  variables: EditAssignmentSubmissionFormMutation$variables;
  response: EditAssignmentSubmissionFormMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "ValidationError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "field",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditAssignmentSubmissionFormMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v1/*: any*/),
        "concreteType": "EditAssignmentSubmissionResponse",
        "kind": "LinkedField",
        "name": "editAssignmentSubmission",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AssignmentSubmission",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "EditAssignmentSubmissionFormFragment"
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditAssignmentSubmissionFormMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v1/*: any*/),
        "concreteType": "EditAssignmentSubmissionResponse",
        "kind": "LinkedField",
        "name": "editAssignmentSubmission",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AssignmentSubmission",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "coverPhoto",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "coverVideo",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "coverVideoDurationSeconds",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "richEditorDescriptionContent",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "submissionLink",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Content",
                "kind": "LinkedField",
                "name": "content",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ContentAttachmentNodeConnection",
                    "kind": "LinkedField",
                    "name": "attachments",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ContentAttachmentNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ContentAttachment",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "mediaUrl",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a5cbd4012aae501deff0a4752baf7e5a",
    "id": null,
    "metadata": {},
    "name": "EditAssignmentSubmissionFormMutation",
    "operationKind": "mutation",
    "text": "mutation EditAssignmentSubmissionFormMutation(\n  $input: EditAssignmentSubmissionInput!\n) {\n  response: editAssignmentSubmission(input: $input) {\n    node {\n      id\n      ...EditAssignmentSubmissionFormFragment\n    }\n    errors {\n      field\n      message\n    }\n  }\n}\n\nfragment EditAssignmentSubmissionFormFragment on AssignmentSubmission {\n  coverPhoto\n  coverVideo\n  coverVideoDurationSeconds\n  richEditorDescriptionContent\n  submissionLink\n  content {\n    attachments {\n      edges {\n        node {\n          id\n          name\n          mediaUrl\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "ec44c5e31bb014c35d7d5c9860cff433";

export default node;
