import { useFormStore } from "@/core/form/store/FormStore"
import { DeleteAssignmentSubmissionButtonMutation } from "@/product/course/assignments/submission/__generated__/DeleteAssignmentSubmissionButtonMutation.graphql"
import { useAssignmentSubmissionTabContext } from "@/product/course/curriculum/section/drawer/items/assignment/tabs/AssignmentSubmissionTabContext"
import { GlobalID } from "@/relay/RelayTypes"
import { displayToast } from "@components/toast/ToastProvider"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonChildren,
} from "@disco-ui/button/OverridableDiscoButton"
import DiscoWarningModal from "@disco-ui/modal/DiscoWarningModal"
import { observer } from "mobx-react-lite"
import { useState } from "react"
import { graphql } from "relay-runtime"

interface Props {
  assignmentSubmissionId: GlobalID
  children: OverridableDiscoButtonChildren
  onDelete?(): void
}

const DeleteAssignmentSubmissionButton = observer<Props>((props) => {
  const { assignmentSubmissionId, onDelete, children } = props
  const [isOpen, setIsOpen] = useState(false)

  const { closeDetailsView } = useAssignmentSubmissionTabContext()!

  const form = useFormStore<DeleteAssignmentSubmissionButtonMutation>(
    graphql`
      mutation DeleteAssignmentSubmissionButtonMutation(
        $input: DeleteAssignmentSubmissionInput!
      ) {
        response: deleteAssignmentSubmission(input: $input) {
          node {
            id @deleteRecord
            contentUsage {
              id
              assignmentSubmissions {
                totalCount
              }
            }
          }
          errors {
            field
            message
          }
        }
      }
    `,
    { assignmentSubmissionId }
  )

  return (
    <>
      <OverridableDiscoButton onClick={() => setIsOpen(true)}>
        {children}
      </OverridableDiscoButton>
      {isOpen && (
        <DiscoWarningModal
          testid={"DeleteAssignmentSubmissionButton"}
          isOpen
          confirmationButtonProps={{
            onClick: handleDelete,
            shouldDisplaySpinner: form.isSubmitting,
            children: "Yes, delete it",
          }}
          onClose={() => setIsOpen(false)}
          modalContentLabel={`Delete Submission Dialog`}
          title={`Delete this submission?`}
          description={
            "Are you sure you want to permanently delete this submission? There is no way to undo this action."
          }
        />
      )}
    </>
  )

  async function handleDelete() {
    const { didSave } = await form.submit(form.state)
    if (!didSave) return
    closeDetailsView()
    if (onDelete) onDelete()
    displayToast({
      message: "Submission deleted",
      testid: "DeleteAssignmentSubmissionButton.success-toast",
    })
  }
})

export default DeleteAssignmentSubmissionButton
