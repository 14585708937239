/**
 * @generated SignedSource<<ae490067746d21f3a252e2d7bacd4358>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AssignmentSubmissionDetailsFooterFragment$data = {
  readonly id: string;
  readonly contentId: string;
  readonly content: {
    readonly totalComments: number;
    readonly " $fragmentSpreads": FragmentRefs<"ContentReactionButton_ContentFragment" | "ContentCommentButton_ContentFragment">;
  };
  readonly " $fragmentType": "AssignmentSubmissionDetailsFooterFragment";
};
export type AssignmentSubmissionDetailsFooterFragment$key = {
  readonly " $data"?: AssignmentSubmissionDetailsFooterFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AssignmentSubmissionDetailsFooterFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AssignmentSubmissionDetailsFooterFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contentId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Content",
      "kind": "LinkedField",
      "name": "content",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalComments",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ContentReactionButton_ContentFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ContentCommentButton_ContentFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AssignmentSubmission",
  "abstractKey": null
};

(node as any).hash = "17ace5c841bbe5e8d519c48d98661c1d";

export default node;
