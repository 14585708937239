import useDismissNotifications from "@/apps/util/hooks/useDismissNotifications"
import CommentsSection, { CommentsSectionSkeleton } from "@/comments/CommentsSection"
import { CommentsSectionProvider } from "@/comments/CommentsSectionContext"
import InlineContentAttachmentsSection from "@/content/inline/InlineContentAttachmentsSection"
import ContentReactionButton from "@/product/common/content/button/ContentReactionButton"
import DeleteAssignmentSubmissionButton from "@/product/course/assignments/submission/DeleteAssignmentSubmissionButton"
import EditAssignmentSubmissionButton from "@/product/course/assignments/submission/edit/EditAssignmentSubmissionButton"
import AssignmentSubmissionDetailsComments, {
  AssignmentSubmissionDetailsCommentsSkeleton,
} from "@/product/course/curriculum/section/drawer/items/assignment/tabs/AssignmentSubmissionDetailsComments"
import AssignmentSubmissionDetailsFooter, {
  AssignmentSubmissionDetailsFooterSkeleton,
} from "@/product/course/curriculum/section/drawer/items/assignment/tabs/AssignmentSubmissionDetailsFooter"
import { AssignmentSubmissionDetailsQuery } from "@/product/course/curriculum/section/drawer/items/assignment/tabs/__generated__/AssignmentSubmissionDetailsQuery.graphql"
import useIsWebView from "@/product/util/hook/useIsWebView"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import { UserAvatarStackSkeleton } from "@/user/common/avatar-stack/UserAvatarStack"
import ProfileAvatarWithDetails from "@/user/common/profile-avatar-with-details/ProfileAvatarWithDetails"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import ExternalDiscoTextButton from "@components/buttons/ExternalDiscoTextButton"
import DiscoEditor from "@components/editor/DiscoEditor"
import EditorUtils from "@components/editor/EditorUtils"
import ContentUniqueReactionsList from "@components/reactions/ContentUniqueReactionsList"
import { DiscoIcon, DiscoIconButton, DiscoText } from "@disco-ui"
import { useTheme } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import usePermissions from "@utils/hook/usePermissions"
import { graphql, useLazyLoadQuery } from "react-relay"

interface AssignmentSubmissionDetailsProps {
  submissionId: GlobalID
}

function AssignmentSubmissionDetails({ submissionId }: AssignmentSubmissionDetailsProps) {
  const isWebView = useIsWebView()
  const theme = useTheme()
  const isMobile = useIsMobile()
  const classes = useStyles()

  const { node } = useLazyLoadQuery<AssignmentSubmissionDetailsQuery>(
    graphql`
      query AssignmentSubmissionDetailsQuery($id: ID!) {
        node(id: $id) {
          ... on AssignmentSubmission {
            id
            __typename
            type
            name
            richEditorDescriptionContent
            submissionLink
            contentId
            content {
              ...InlineContentAttachmentsSectionFragment
              ...ContentReactionButton_ContentFragment
              ...ContentCommentButton_ContentFragment
            }
            ...usePermissionsFragment
            ...AssignmentSubmissionDetailsFooterFragment
            ...AssignmentSubmissionDetailsCommentsFragment
            submissionUsers {
              edges {
                node {
                  id
                  user {
                    ...ProfileAvatarWithDetailsFragment
                  }
                }
              }
            }
          }
        }
      }
    `,
    { id: submissionId },
    { fetchPolicy: "network-only" }
  )

  const submission = Relay.narrowNodeType(node, "AssignmentSubmission")
  const permissions = usePermissions(submission)

  const submissionUsers = Relay.connectionToArray(submission?.submissionUsers)
  const user = submissionUsers[0] ? submissionUsers[0].user : null

  // Mark notification as read when viewing details of submission
  useDismissNotifications({ entityId: submission?.id || "" }, { skip: !submission })

  if (!submission) return null

  const canManage = permissions.has("assignment_submission.manage")
  const canEdit = permissions.has("assignment_submission.edit")

  return (
    <>
      <div className={classes.container}>
        <CommentsSectionProvider>
          <div
            className={classes.bodyContainer}
            data-testid={"AssignmentSubmissionDetails"}
          >
            <div className={classes.submission}>
              <div className={classes.header}>
                <DiscoText variant={"body-lg-600"}>{"Submission"}</DiscoText>

                <div className={classes.rhs}>
                  {(canManage || canEdit) && (
                    <>
                      {/* Only the learner who submitted can edit submission */}
                      {canEdit && (
                        <EditAssignmentSubmissionButton
                          assignmentSubmissionId={submission.id!}
                        >
                          {(props) => (
                            <div {...props}>
                              <DiscoIconButton
                                testid={"AssignmentSubmissionDetails.edit"}
                                variant={"outlined"}
                                width={40}
                                height={40}
                                className={classes.icon}
                              >
                                <DiscoIcon icon={"pencil"} />
                              </DiscoIconButton>
                            </div>
                          )}
                        </EditAssignmentSubmissionButton>
                      )}

                      {/* We can't delete the submission from webview because after deletion, 
                      we won't be able to navigate the user back to to previous screen on the mobile app */}
                      {canManage && !isWebView && (
                        <DeleteAssignmentSubmissionButton
                          assignmentSubmissionId={submission.id!}
                        >
                          {(props) => (
                            <div {...props}>
                              <DiscoIconButton
                                testid={"AssignmentSubmissionDetails.delete"}
                                variant={"outlined"}
                                width={40}
                                height={40}
                                className={classes.icon}
                              >
                                <DiscoIcon icon={"trash"} />
                              </DiscoIconButton>
                            </div>
                          )}
                        </DeleteAssignmentSubmissionButton>
                      )}
                    </>
                  )}
                </div>
              </div>

              {/* Submitter */}
              {isMobile && user && (
                <ProfileAvatarWithDetails
                  userKey={user}
                  titleVariant={"body-sm"}
                  testid={"ProfileAvatarWithDetails"}
                  linkToProfile={false}
                  disableClick
                  className={classes.avatar}
                />
              )}

              {/* Submission link */}
              {submission.submissionLink && (
                <ExternalDiscoTextButton
                  title={"View Submission Link"}
                  to={submission.submissionLink}
                  iconPosition={"right"}
                  className={classes.submissionLink}
                />
              )}

              {/* Submission attachments */}
              {submission.content && (
                <InlineContentAttachmentsSection contentKey={submission.content} />
              )}

              {/* Submission content */}
              {!EditorUtils.isEmpty(submission.richEditorDescriptionContent) && (
                <DiscoEditor
                  // although relay store updates the editor does not display updated data
                  // without passing key to manually trigger re-render
                  key={submission.richEditorDescriptionContent}
                  className={classes.editor}
                  defaultValue={submission.richEditorDescriptionContent}
                  textColor={theme.palette.text.secondary}
                  readOnly
                  disableGutter
                />
              )}
            </div>

            {/* Submission comments */}
            {isMobile && (
              <>
                <div className={classes.reactionRow}>
                  <ContentReactionButton
                    testid={"AssignmentSubmissionFooter"}
                    contentId={submission.contentId}
                    contentKey={submission.content}
                    hideText
                  />
                  <ContentUniqueReactionsList contentId={submission.contentId} />
                </div>
                <div className={classes.commentsSection}>
                  <CommentsSection
                    id={`AssignmentSubmissionDetails-${submission.id}.comment-section`}
                    testid={"AssignmentSubmissionDetails.comment-section"}
                    contentId={submission.contentId}
                    headerVariant={"body-md-600"}
                    classes={{
                      commentsList: classes.commentsList,
                    }}
                    collapsed
                  />
                </div>
              </>
            )}
          </div>
          <AssignmentSubmissionDetailsFooter submissionKey={submission} />
        </CommentsSectionProvider>
      </div>

      {!isMobile && <AssignmentSubmissionDetailsComments submissionKey={submission} />}
    </>
  )
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    display: "grid",
    width: "100%",
    height: "100%",
    [theme.breakpoints.up("md")]: {
      paddingBottom: theme.spacing(4),
    },
  },
  bodyContainer: {
    display: "grid",
    overflow: "hidden auto",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      scrollbarWidth: "none",
      overflow: "auto",
    },
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  reactionRow: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    justifyContent: "flex-start",
    paddingLeft: theme.spacing(1),
    alignSelf: "flex-end",
  },
  avatar: {
    margin: theme.spacing(2, 0),
  },
  submission: {
    padding: theme.spacing(2.5),
  },
  submissionLink: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  editor: {
    paddingBottom: theme.spacing(1.5),
    height: "100%",
  },
  rhs: {
    display: "flex",
    gap: theme.spacing(1),
  },
  icon: {
    padding: theme.spacing(1),
  },
  commentsSection: {
    padding: theme.spacing(2.5, 2),
  },
  commentsList: {
    paddingTop: theme.spacing(2),
  },
}))

export function AssignmentSubmissionDetailsSkeleton() {
  const classes = useStyles()
  const isMobile = useIsMobile()

  return (
    <>
      <div className={classes.container} style={{ width: "100%" }}>
        <div className={classes.bodyContainer}>
          <div className={classes.submission}>
            <div className={classes.header}>
              <DiscoText variant={"body-lg-600"}>{"Submission"}</DiscoText>
            </div>
            {isMobile && <UserAvatarStackSkeleton stackSize={1} avatarSize={40} />}
            <Skeleton variant={"text"} width={"100%"} height={"200px"} />
          </div>
          {isMobile && (
            <div className={classes.commentsSection}>
              <CommentsSectionSkeleton headerVariant={"body-md-600"} />
            </div>
          )}
          <AssignmentSubmissionDetailsFooterSkeleton />
        </div>
      </div>
      {!isMobile && <AssignmentSubmissionDetailsCommentsSkeleton />}
    </>
  )
}

export default Relay.withSkeleton({
  component: AssignmentSubmissionDetails,
  skeleton: AssignmentSubmissionDetailsSkeleton,
})
