import BookmarkButton from "@/bookmarks/BookmarkButton"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { GlobalDrawerParams } from "@/core/context/GlobalDrawerProvider"
import { useLabels } from "@/core/context/LabelsContext"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { OccurrenceMedia } from "@/occurrence/api/occurrenceApiModels"
import DeleteOccurrenceButton from "@/occurrence/button/delete-button/DeleteOccurrenceButton"
import UploadEventVideoButton from "@/occurrence/live-box/UploadEventVideoButton"
import OccurrenceRSVPAttendanceStatus from "@/organization/occurrence/OccurrenceRSVPAttendanceStatus"
import OccurrenceStatusChip from "@/organization/occurrence/OccurrenceStatusChip"
import OccurrenceWatchVideoButton from "@/organization/occurrence/OccurrenceWatchVideoButton"
import MemberGroupEventTagList from "@/product/common/member-group/common/tag/MemberGroupEventTagList"
import OccurrenceHostsAvatarStack from "@/product/course/event/components/OccurrenceHostsAvatarStack"
import OccurrenceNameAndTime from "@/product/course/event/components/OccurrenceNameAndTime"
import { OccurrenceListItemFragment$key } from "@/product/course/event/list/item/__generated__/OccurrenceListItemFragment.graphql"
import useIsWebView from "@/product/util/hook/useIsWebView"
import Relay from "@/relay/relayUtils"
import useUserTimezone from "@/user/util/useUserTimezone"
import VideoIcon from "@/core/ui/iconsax/linear/video-square.svg"
import styleIf from "@assets/style/util/styleIf"
import CoverPhoto from "@components/cover-photo/CoverPhoto"
import DaySquare from "@components/date/day-square/DaySquare"
import {
  DiscoButton,
  DiscoChip,
  DiscoIcon,
  DiscoLink,
  DiscoSection,
  DiscoText,
  DiscoTextButton,
  DiscoTextSkeleton,
  DiscoTooltip,
} from "@disco-ui"
import DiscoContainerButton from "@disco-ui/button/DiscoContainerButton"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import DiscoMoreActionsDropdown from "@disco-ui/dropdown/DiscoMoreActionsDropdown"
import DiscoTag from "@disco-ui/tag/DiscoTag"
import { Grid, useMediaQuery, useTheme } from "@material-ui/core"
import { ClassNameMap } from "@material-ui/core/styles/withStyles"
import { Skeleton } from "@material-ui/lab"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import usePermissions from "@utils/hook/usePermissions"
import { DATE_FORMAT } from "@utils/time/timeConstants"
import { formatDateWithOptions } from "@utils/time/timeUtils"
import { TestIDProps } from "@utils/typeUtils"
import { setSearchParams } from "@utils/url/urlUtils"
import classNames from "classnames"
import pluralize from "pluralize"
import { useState } from "react"
import { graphql, useFragment } from "react-relay"
import { generatePath, useHistory, useLocation } from "react-router-dom"

export interface OccurrenceListItemProps extends TestIDProps {
  occurrenceKey: OccurrenceListItemFragment$key
  variant?: "list-item" | "card"
  hideCover?: boolean
  classes?: ClassNameMap<"root">
  viewOnly?: boolean
  enableRecurringEventConfirmModal?: boolean
  onClick?: () => void
  disableRegistration?: boolean
  navigateOnClick?: boolean
}

function OccurrenceListItem({
  occurrenceKey,
  testid = "OccurrenceListItem",
  variant: v = "list-item",
  hideCover = false,
  viewOnly = false,
  enableRecurringEventConfirmModal = false,
  onClick,
  disableRegistration = false,
  classes: customClasses,
  navigateOnClick = false,
}: OccurrenceListItemProps) {
  const activeProduct = useActiveProduct()
  const location = useLocation()
  const timeZone = useUserTimezone()
  const isMobile = useIsMobile()
  const { admin_experience: experienceLabel } = useLabels()
  const history = useHistory()
  const variant = isMobile ? "card" : v
  const classes = useStyles({ variant, viewOnly })
  const theme = useTheme()
  const mdDown = useMediaQuery(theme.breakpoints.down("md"))
  const isWebView = useIsWebView()

  const occurrence = useFragment<OccurrenceListItemFragment$key>(
    graphql`
      fragment OccurrenceListItemFragment on Occurrence {
        id
        name
        datetimeRange
        coverPhoto
        status
        isComplete
        visibility
        appId
        hosts {
          totalCount
        }
        product {
          id
          cover
          type
          name
          slug
          status
        }
        event {
          id
          ...MemberGroupEventTagListFragment
          ...usePermissionsFragment
          memberGroups {
            totalCount
          }
        }
        content {
          id
          ...BookmarkButtonContentFragment
        }
        ...OccurrenceNameAndTimeFragment
        ...OccurrenceRSVPAttendanceStatusFragment
        ...OccurrenceStatusChipFragment
        ...OccurrenceWatchVideoButtonFragment
        ...DeleteOccurrenceButtonFragment
        guestReport {
          totalCount
        }
        rsvps(first: 3) {
          totalCount
          edges {
            node {
              id
              user {
                fullName
              }
            }
          }
        }
        media(first: 1) {
          edges {
            node {
              id
              url
              kind
            }
          }
        }
      }
    `,
    occurrenceKey
  )

  const rsvps = Relay.connectionToArray(occurrence.rsvps)
  const hostCount = occurrence.hosts.totalCount
  const canManage = usePermissions(occurrence.event).has("events.manage")

  const [, setOccurrenceMedia] = useState<OccurrenceMedia | null>({
    ...Relay.connectionToArray(occurrence.media)[0],
  })

  switch (variant) {
    case "card":
      return (
        <div
          key={occurrence.id}
          className={classNames(classes.card, customClasses?.root)}
          data-testid={testid}
        >
          <DiscoContainerButton
            onClick={handleClick}
            testid={testid}
            className={classes.eventLink}
          >
            {renderCover()}
            <div className={classes.details}>
              {renderTitle()}
              {renderRSVPs()}
              {renderHostedBy()}
              {renderGroups()}
            </div>

            <div className={classes.status}>{renderStatus()}</div>
          </DiscoContainerButton>
        </div>
      )
    case "list-item":
    default:
      return (
        <DiscoContainerButton
          onClick={handleClick}
          testid={testid}
          className={classes.buttonContainer}
        >
          <DiscoSection
            className={classNames(classes.listItem, customClasses?.root)}
            raiseOnHover={!viewOnly}
            padding={2}
            groovyDepths={"insideCard"}
            border
          >
            <Grid container className={classes.container}>
              {renderCover()}
              <Grid
                container
                item
                xs
                direction={"column"}
                justifyContent={"space-between"}
              >
                {renderTitle()}
                <Grid
                  item
                  container
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  className={classes.footerContainer}
                >
                  <Grid
                    container
                    item
                    className={classes.footer}
                    alignItems={"center"}
                    justifyContent={"flex-start"}
                  >
                    {renderRSVPs()}
                    {renderHostedBy()}
                    {renderGroups()}
                    {renderProductDetails()}
                  </Grid>

                  <div className={classes.status}>{renderStatus()}</div>
                </Grid>
              </Grid>
            </Grid>
          </DiscoSection>
        </DiscoContainerButton>
      )
  }

  function renderCover() {
    if (hideCover) return null
    if (isMobile && !isWebView) return null
    const coverPhoto = occurrence.coverPhoto || occurrence.product.cover
    switch (variant) {
      case "card":
        return (
          <>
            {occurrence.isComplete && (
              <DiscoTag name={"Event Ended"} className={classes.endedTag} />
            )}

            <CoverPhoto coverPhoto={coverPhoto} customClassName={classes.cover}>
              <div className={classes.daySquareContainer}>
                <DaySquare
                  testid={`${testid}.DaySquare`}
                  date={new Date(occurrence.datetimeRange[0])}
                  formatterOptions={{ timeZone }}
                  customClassName={classes.daySquare}
                />
              </div>
            </CoverPhoto>
          </>
        )
      case "list-item":
      default:
        if (mdDown) return null
        return (
          <Grid item xs className={classes.cover}>
            {occurrence.isComplete && (
              <DiscoTag name={"Event Ended"} className={classes.endedTag} />
            )}
            <CoverPhoto coverPhoto={coverPhoto} customClassName={classes.coverPhoto} />
          </Grid>
        )
    }
  }

  function renderTitle() {
    const date = (
      <DaySquare
        testid={`${testid}.DaySquare`}
        date={new Date(occurrence.datetimeRange[0])}
        formatterOptions={{ timeZone }}
        customClassName={classes.daySquare}
      />
    )

    switch (variant) {
      case "card":
        return (
          <div className={classes.timeAndTitle}>
            {/* Date */}
            {(hideCover || isMobile) && <>{date}</>}
            <div>
              {/* Time */}
              <DiscoText variant={"body-xs-600-uppercase"} color={"primary.main"}>
                {formatDateWithOptions({
                  timeZone,
                  format: DATE_FORMAT.REVERSE_DATE_SHORT_WEEK_DAY_TIME_WITHOUT_YEAR,
                })(new Date(occurrence.datetimeRange[0]))}
              </DiscoText>

              {/* Title */}
              <DiscoText
                variant={"body-md-600"}
                truncateText={2}
                testid={"OccurrenceCard.summary"}
                marginBottom={1}
              >
                {occurrence.name}
              </DiscoText>
            </div>
          </div>
        )
      case "list-item":
      default:
        return (
          <Grid item container className={classes.headerContainer} wrap={"nowrap"}>
            <Grid item xs={"auto"}>
              {date}
            </Grid>
            <Grid
              item
              container
              xs
              justifyContent={"space-between"}
              zeroMinWidth
              wrap={"nowrap"}
            >
              <OccurrenceNameAndTime
                occurrenceKey={occurrence}
                classes={{ root: classes.occurrenceNameAndTime }}
              />

              <div className={classes.rhsWrapper}>
                <BookmarkButton
                  testid={"OccurrenceListItem"}
                  contentKey={occurrence.content}
                  kind={"occurrence"}
                />
                {renderMoreActions()}
              </div>
            </Grid>
          </Grid>
        )
    }
  }

  function renderMoreActions() {
    if (viewOnly) return null
    if (!canManage) return null
    switch (variant) {
      case "card":
        return null
      case "list-item":
      default:
        return (
          <Grid item xs={"auto"}>
            <DiscoMoreActionsDropdown testid={testid}>
              <DiscoDropdownItem
                title={"Edit Event"}
                testid={`${testid}.edit-button`}
                to={{
                  search: setSearchParams<GlobalDrawerParams<"event">>(location.search, {
                    drawerOccurrenceId: occurrence.id,
                    drawerEventTab: "settings",
                  }),
                }}
              />
              <DeleteOccurrenceButton occurrenceKey={occurrence}>
                {(props) => (
                  <DiscoDropdownItem
                    {...props}
                    testid={`${testid}.delete-button`}
                    title={"Delete Event"}
                  />
                )}
              </DeleteOccurrenceButton>
            </DiscoMoreActionsDropdown>
          </Grid>
        )
    }
  }

  function renderRSVPs() {
    if (!canManage) return null
    if (occurrence.isComplete) return null
    const totalAttendanceCount =
      (occurrence.rsvps?.totalCount || 0) + (occurrence.guestReport?.totalCount || 0)

    return (
      <DiscoTooltip disabled={!occurrence.rsvps?.totalCount} content={getRSVPs()}>
        <div>
          <Grid item container alignItems={"center"}>
            <DiscoIcon
              icon={"users"}
              width={20}
              height={20}
              color={theme.palette.text.secondary}
              marginRight={1}
            />
            <DiscoText variant={"body-sm"} color={"text.secondary"}>
              {`${totalAttendanceCount} ${pluralize("person", totalAttendanceCount)} ${
                totalAttendanceCount === 1 ? "is" : "are"
              } attending`}
            </DiscoText>
          </Grid>
        </div>
      </DiscoTooltip>
    )
  }

  function renderHostedBy() {
    if (canManage || !hostCount) return null

    return (
      <Grid item container alignItems={"center"}>
        <DiscoIcon
          icon={"user"}
          width={20}
          height={20}
          color={theme.palette.text.secondary}
          marginRight={1}
        />
        <DiscoText variant={"body-sm"} color={"text.secondary"} marginRight={1}>
          {"Hosted by"}
        </DiscoText>
        <OccurrenceHostsAvatarStack occurrenceId={occurrence.id} viewOnly={viewOnly} />
      </Grid>
    )
  }

  function renderProductDetails() {
    if (activeProduct?.type === "course") return null
    if (occurrence.product.type === "community_event") return null

    return (
      <Grid item container alignItems={"center"}>
        <DiscoIcon
          icon={"lightbulb"}
          width={20}
          height={20}
          color={theme.palette.text.secondary}
          marginRight={1}
        />
        <DiscoText variant={"body-sm"} color={"text.secondary"}>
          {"Hosted in "}
          <DiscoLink
            to={generatePath(ROUTE_NAMES.PRODUCT.DASHBOARD, {
              productSlug: occurrence.product.slug,
            })}
          >
            <DiscoText variant={"body-sm-700"} color={"primary.main"} component={"span"}>
              {occurrence.product.name}
            </DiscoText>
          </DiscoLink>
        </DiscoText>

        {Boolean(occurrence.event.memberGroups.totalCount) && (
          <MemberGroupEventTagList
            hideDefault
            eventKey={occurrence.event}
            testid={testid}
            className={classes.groups}
            hideAfter={1}
          />
        )}
      </Grid>
    )
  }

  function renderGroups() {
    if (!occurrence.event.memberGroups.totalCount) return null
    if (occurrence.visibility !== "member_group") return null
    if (!activeProduct && occurrence.product.type === "course") return null

    switch (variant) {
      case "card":
        return (
          <Grid item container alignItems={"center"}>
            <DiscoIcon
              icon={"group-share"}
              width={20}
              height={20}
              color={theme.palette.text.secondary}
              marginRight={1}
            />

            <DiscoText variant={"body-sm"} color={"text.secondary"}>
              {pluralize("sub-group", occurrence.event.memberGroups.totalCount, true)}
            </DiscoText>
          </Grid>
        )
      case "list-item":
      default:
        return (
          <Grid item container alignItems={"center"}>
            <DiscoIcon
              icon={"group-share"}
              width={20}
              height={20}
              color={theme.palette.text.secondary}
            />

            <MemberGroupEventTagList
              hideDefault
              eventKey={occurrence.event}
              testid={testid}
              className={classes.groups}
              hideAfter={2}
            />
          </Grid>
        )
    }
  }

  function renderStatus() {
    if (viewOnly && disableRegistration) return null

    if (
      occurrence.product.status === "draft" &&
      occurrence.product.type !== "community_event"
    ) {
      return (
        <DiscoTooltip
          content={`You can publish the host ${experienceLabel.singular} to remove the draft status from this and all other events in the ${experienceLabel.singular}.`}
        >
          <DiscoChip color={"yellow"} label={"Draft"} />
        </DiscoTooltip>
      )
    }

    if (occurrence.status === "draft") {
      return (
        <DiscoTooltip content={"You can publish this Event to remove the draft status."}>
          <DiscoChip color={"yellow"} label={"Draft"} />
        </DiscoTooltip>
      )
    }

    return (
      <Grid
        container
        item
        className={classes.footer}
        alignItems={"center"}
        justifyContent={"flex-end"}
      >
        <OccurrenceStatusChip occurrenceKey={occurrence} className={classes.chip} />
        <OccurrenceWatchVideoButton occurrenceKey={occurrence}>
          {(buttonProps) => (
            <DiscoTextButton
              color={"primary"}
              leftIcon={<VideoIcon />}
              className={classes.videoButton}
              {...buttonProps}
            >
              <DiscoText variant={"body-sm-600"} setColor={"currentColor"}>
                {"Watch Recording"}
              </DiscoText>
            </DiscoTextButton>
          )}
        </OccurrenceWatchVideoButton>

        {!occurrence.media?.edges[0]?.node?.url && canManage && occurrence.isComplete && (
          <UploadEventVideoButton
            occurrenceId={occurrence.id}
            setOccurrenceMedia={setOccurrenceMedia}
          >
            {(buttonProps) => (
              <DiscoButton
                {...buttonProps}
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  buttonProps.onClick(e)
                }}
                color={"grey"}
                variant={"outlined"}
                width={variant === "card" || isMobile ? "100%" : undefined}
              >
                {"Upload Recording"}
              </DiscoButton>
            )}
          </UploadEventVideoButton>
        )}

        <OccurrenceRSVPAttendanceStatus
          occurrenceKey={occurrence}
          width={variant === "card" || isMobile ? "100%" : undefined}
          enableRecurringEventConfirmModal={enableRecurringEventConfirmModal}
          hideJoinButton={viewOnly}
        />
      </Grid>
    )
  }

  function redirectToEventsPage() {
    const productSlug = occurrence.product.slug
    const path = productSlug.length
      ? generatePath(ROUTE_NAMES.PRODUCT.EVENTS.LIST.ROOT, {
          productSlug,
        })
      : generatePath(ROUTE_NAMES.COMMUNITY.EVENTS.APP, {
          appId: occurrence.appId || "",
        })

    history.push({
      pathname: path,
      search: setSearchParams<GlobalDrawerParams<"event">>(location.search, {
        drawerOccurrenceId: occurrence.id,
        drawerEventTab: "details",
      }),
    })
  }

  function handleClick() {
    if (viewOnly) return
    if (onClick) onClick()
    if (navigateOnClick) {
      redirectToEventsPage()
      return
    }
    history.push({
      ...location,
      search: setSearchParams<GlobalDrawerParams<"event">>(location.search, {
        drawerOccurrenceId: occurrence.id,
        drawerEventTab: "details",
      }),
    })
  }

  function getRSVPs() {
    if (!occurrence.rsvps?.totalCount) return ""
    const names = rsvps.map((rsvp) => rsvp.user.fullName)
    return `${names.join(", ")} and ${pluralize(
      "other",
      occurrence.rsvps.totalCount - names.length,
      true
    )} are attending`
  }
}

interface StyleProps {
  variant?: "list-item" | "card"
  viewOnly?: boolean
}

const useStyles = makeUseStyles((theme) => ({
  cover: ({ variant }: StyleProps) => ({
    ...styleIf(variant === "list-item", {
      maxWidth: "264px",
      maxHeight: "132px",
      display: "flex",
      position: "relative",
    }),
    ...styleIf(variant === "card", {
      borderTopLeftRadius: theme.measure.borderRadius.medium,
      borderTopRightRadius: theme.measure.borderRadius.medium,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      paddingTop: "50%",
      backgroundSize: "cover",
    }),
  }),
  coverPhoto: {
    width: "100%",
    backgroundSize: "cover",
  },
  endedTag: {
    position: "absolute",
    zIndex: theme.zIndex.raise1,
    top: theme.spacing(1),
    left: theme.spacing(1),
  },
  container: {
    gap: theme.spacing(2),
  },
  mobileSkeletonContainer: {
    gap: theme.spacing(1.5),
    margin: theme.spacing(-1),
  },
  headerContainer: {
    gap: theme.spacing(1.5),
  },
  footer: ({ variant }: StyleProps) => ({
    gap: theme.spacing(0.5),
    ...styleIf(variant === "list-item", {
      width: "fit-content",
    }),
    ...styleIf(variant === "card", {
      width: "100%",
      justifyContent: "center",
    }),
  }),
  footerContainer: {
    gap: theme.spacing(1.5),
    marginTop: theme.spacing(1),
    alignItems: "flex-end",
  },
  occurrenceNameAndTime: {
    marginLeft: 0,
  },
  videoButton: ({ variant }: StyleProps) => ({
    color: theme.palette.primary.main,
    ...styleIf(variant === "card", {
      width: "100%",
    }),
  }),
  groups: {
    margin: theme.spacing(0, 1),
  },
  daySquare: {
    marginBottom: theme.spacing(1),
    flexShrink: 0,
  },
  rhsWrapper: {
    display: "flex",
    alignItems: "flex-start",
    gap: theme.spacing(1),
  },
  card: {
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.measure.borderRadius.big,
    border: theme.palette.constants.borderSmall,
    display: "flex",
    position: "relative",
    margin: theme.spacing(0, 1.25, 2.25),
    boxShadow: theme.palette.groovyDepths.insideCard,

    "&:hover": {
      boxShadow: theme.palette.groovyDepths.boxShadow,
    },
  },
  eventLink: {
    width: "100%",
    minWidth: 0,
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  listItem: {
    marginBottom: theme.spacing(2),
  },
  buttonContainer: ({ viewOnly }: StyleProps) => ({
    ...styleIf(viewOnly, {
      "&:hover": {
        cursor: "default",
      },
    }),
  }),
  daySquareContainer: {
    position: "absolute",
    border: `2px solid ${theme.palette.background.paper[20]}`,
    boxSizing: "content-box",
    borderRadius: theme.measure.borderRadius.medium,
    bottom: 0,
    right: 16,
    transform: "translateY(50%)",
  },
  details: {
    padding: theme.spacing(2),
    textAlign: "start",
    flex: "1 1 auto",
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
  timeAndTitle: {
    display: "flex",
    gap: theme.spacing(2),
  },
  status: ({ variant }: StyleProps) => ({
    ...styleIf(variant === "card", {
      padding: theme.spacing(0, 2, 2, 2),
    }),
  }),
  chip: ({ variant }: StyleProps) => ({
    ...styleIf(variant === "card", {
      marginBottom: theme.spacing(1.5),
    }),
    ...styleIf(variant === "list-item", {
      marginRight: theme.spacing(1.5),
    }),
  }),
}))

export function OccurrenceListItemSkeleton({
  variant = "list-item",
}: Pick<OccurrenceListItemProps, "variant">) {
  const theme = useTheme()
  const classes = useStyles({ variant })
  const smDown = useMediaQuery(theme.breakpoints.down("sm"))
  return (
    <DiscoSection marginBottom={2}>
      <Grid container className={classes.mobileSkeletonContainer}>
        {smDown ? (
          // Mobile skeleton
          <Grid container direction={"column"} className={classes.container}>
            <Grid item container direction={"row"} alignItems={"center"}>
              <Grid item>
                <Skeleton
                  variant={"rect"}
                  style={{
                    borderRadius: theme.measure.borderRadius.medium,
                    marginRight: "8px",
                  }}
                  height={45}
                  width={45}
                />
              </Grid>
              <Grid item container direction={"column"} xs>
                <Grid item>
                  <Skeleton
                    variant={"text"}
                    style={{ borderRadius: theme.measure.borderRadius.medium }}
                    width={"60%"}
                    height={20}
                  />
                </Grid>
                <Grid item xs>
                  <Skeleton
                    variant={"text"}
                    style={{ borderRadius: theme.measure.borderRadius.medium }}
                    width={"60%"}
                    height={30}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item container direction={"row"} alignItems={"center"}>
              <Grid item>
                <Skeleton
                  style={{ marginRight: "8px" }}
                  variant={"circle"}
                  width={24}
                  height={24}
                />
              </Grid>
              <Grid item xs>
                <Skeleton
                  variant={"text"}
                  style={{ borderRadius: theme.measure.borderRadius.medium }}
                  width={170}
                  height={20}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Skeleton
                variant={"rect"}
                style={{ borderRadius: theme.measure.borderRadius.medium }}
                height={30}
                width={"100%"}
              />
            </Grid>
          </Grid>
        ) : (
          // Full screeen skeleton
          <Grid container className={classes.container}>
            <Grid item xs={"auto"}>
              <Skeleton
                variant={"rect"}
                style={{ borderRadius: theme.measure.borderRadius.medium }}
                height={120}
                width={240}
              />
            </Grid>
            <Grid container item xs direction={"column"} justifyContent={"space-between"}>
              <Grid item container className={classes.headerContainer}>
                <Grid item xs={"auto"}>
                  <Skeleton
                    variant={"rect"}
                    style={{ borderRadius: theme.measure.borderRadius.medium }}
                    width={56}
                    height={56}
                  />
                </Grid>
                <Grid item container xs justifyContent={"space-between"} zeroMinWidth>
                  <Grid item container xs direction={"column"}>
                    <DiscoTextSkeleton width={"100%"} />
                    <DiscoTextSkeleton width={"100%"} />
                  </Grid>
                  <Grid item xs={"auto"}>
                    <Skeleton
                      variant={"circle"}
                      width={24}
                      height={24}
                      style={{ marginLeft: "8px" }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <DiscoTextSkeleton width={"100%"} />
            </Grid>
          </Grid>
        )}
      </Grid>
    </DiscoSection>
  )
}

export default Relay.withSkeleton<OccurrenceListItemProps>({
  component: OccurrenceListItem,
  skeleton: OccurrenceListItemSkeleton,
})
