import CommentEditor from "@/comments/editor/CommentEditor"
import CommentUtils from "@/comments/utils/CommentUtils"
import { CONTENT_DRAWER_FOOTER_HEIGHT } from "@/content-usage/drawer/footer/InlineContentDrawerFooter"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { ContentCommentButtonSkeleton } from "@/product/common/content/button/ContentCommentButton"
import ContentReactionButton, {
  ContentReactionButtonSkeleton,
} from "@/product/common/content/button/ContentReactionButton"
import { AssignmentSubmissionDetailsFooterFragment$key } from "@/product/course/curriculum/section/drawer/items/assignment/tabs/__generated__/AssignmentSubmissionDetailsFooterFragment.graphql"
import Relay from "@/relay/relayUtils"
import ContentUniqueReactionsList from "@components/reactions/ContentUniqueReactionsList"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"
export interface AssignmentSubmissionDetailsFooterProps extends TestIDProps {
  submissionKey: AssignmentSubmissionDetailsFooterFragment$key
}

function AssignmentSubmissionDetailsFooter({
  submissionKey,
}: AssignmentSubmissionDetailsFooterProps) {
  const isMobile = useIsMobile()
  const classes = useStyles()
  const submission = useFragment<AssignmentSubmissionDetailsFooterFragment$key>(
    graphql`
      fragment AssignmentSubmissionDetailsFooterFragment on AssignmentSubmission {
        id
        contentId
        content {
          totalComments
          ...ContentReactionButton_ContentFragment
          ...ContentCommentButton_ContentFragment
        }
      }
    `,
    submissionKey
  )

  return (
    <>
      {!isMobile && (
        <div className={classes.footer}>
          <div className={classes.lhs}>
            <ContentReactionButton
              testid={"AssignmentSubmissionFooter"}
              contentId={submission.contentId}
              contentKey={submission.content}
              hideText
            />
            <ContentUniqueReactionsList contentId={submission.contentId} />
          </div>
        </div>
      )}
      {isMobile && (
        <div className={classes.footer}>
          <CommentEditor
            key={`comment-editor-${submission.contentId}-${submission.content.totalComments}`}
            testid={"AssignmentSubmissionDetails.comment-section"}
            contentId={submission.contentId}
            onCommentSubmitted={CommentUtils.scrollToNewComment}
            enforceMaxHeight
          />
        </div>
      )}
    </>
  )
}

const useStyles = makeUseStyles((theme) => ({
  footer: {
    borderTop: theme.palette.constants.borderSmall,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(0, 2.5, 1.5, 2.5),
    [theme.breakpoints.up("md")]: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: theme.spacing(0, 2.5),
      position: "absolute",
      bottom: 0,
      zIndex: 2,
      height: CONTENT_DRAWER_FOOTER_HEIGHT,
      width: "100%",
    },
  },
  lhs: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    justifyContent: "flex-start",
  },
}))

export function AssignmentSubmissionDetailsFooterSkeleton() {
  const classes = useStyles()
  return (
    <div className={classes.footer}>
      <div className={classes.lhs}>
        <ContentReactionButtonSkeleton />
        <ContentCommentButtonSkeleton />
      </div>
    </div>
  )
}

export default Relay.withSkeleton({
  component: observer(AssignmentSubmissionDetailsFooter),
  skeleton: AssignmentSubmissionDetailsFooterSkeleton,
})
