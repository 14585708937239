import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import EditAssignmentSubmissionForm from "@/product/course/assignments/submission/edit/EditAssignmentSubmissionForm"
import { GlobalID } from "@/relay/RelayTypes"
import Modal from "@components/modal/Modal"
import { DiscoText } from "@disco-ui"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonChildren,
} from "@disco-ui/button/OverridableDiscoButton"
import { observer } from "mobx-react-lite"
import { useState } from "react"

interface Props {
  assignmentSubmissionId: GlobalID
  children: OverridableDiscoButtonChildren
}

const EditAssignmentSubmissionButton = observer<Props>((props) => {
  const { assignmentSubmissionId, children } = props

  const [isOpen, setIsOpen] = useState(false)
  const classes = useStyles()

  return (
    <>
      <OverridableDiscoButton onClick={() => setIsOpen(true)}>
        {children}
      </OverridableDiscoButton>
      {isOpen && (
        <Modal
          isOpen
          onClose={handleCloseModal}
          modalContentLabel={"Edit Submission"}
          customClassName={classes.container}
        >
          <DiscoText
            variant={"heading-md"}
            marginBottom={3}
            testid={"EditAssignmentSubmissionButton.modal-title"}
          >
            {"Edit Submission"}
          </DiscoText>
          <EditAssignmentSubmissionForm
            assignmentSubmissionId={assignmentSubmissionId}
            onSave={handleCloseModal}
            onCancel={handleCloseModal}
          />
        </Modal>
      )}
    </>
  )

  function handleCloseModal() {
    setIsOpen(false)
  }
})

export default EditAssignmentSubmissionButton

const useStyles = makeUseStyles((theme) => ({
  container: {
    padding: theme.spacing(4),
    maxWidth: "600px",
  },
}))
