import { useContentUsageDrawer } from "@/content-usage/drawer/useContentUsageDrawer"
import { MemberGroupTagFragment$key } from "@/product/common/member-group/common/tag/__generated__/MemberGroupTagFragment.graphql"
import { GlobalID } from "@/relay/RelayTypes"
import { SelectOption } from "@disco-ui"
import {
  DiscoTableSortOption,
  DiscoTableToolbarStateWithSort,
} from "@disco-ui/table/header/search/DiscoTableSortDropdown"
import { ReactNode, createContext, useContext, useState } from "react"

type AssignmentSubmissionMemberGroupFilterInput = {
  memberGroupId?: string | null
  unassigned?: boolean | null
}

export const MEMBERS_PER_PAGE = 10

export type SelectedOrderByOption = {
  value: string
  title: string
}

export type MemberGroupFilterOption = SelectOption<"all" | "unassigned" | GlobalID> & {
  groupKey: MemberGroupTagFragment$key | null
}

export const ORDER_BY_OPTIONS = [
  {
    value: "member",
    title: "Member",
    context: {
      order: { field: "member" },
    },
  },
  {
    value: "createdAt",
    title: "Submitted On",
    context: {
      order: { field: "createdAt" },
    },
  },
  {
    value: "likes",
    title: "Reactions",
    context: {
      order: { field: "likes" },
    },
  },
  {
    value: "comments",
    title: "Comments",
    context: {
      order: { field: "comments" },
    },
  },
]

type AssignmentSubmissionTabContextValue = {
  handleSearchChange: (value: string) => void
  handleOrderBySelect: (sortOption: DiscoTableSortOption) => void
  closeDetailsView: () => void
  handleSelectGroupFilter: (selectedValue: string | null) => void
  mapSelectedFilterToInput: () => AssignmentSubmissionMemberGroupFilterInput
  toolbarState: DiscoTableToolbarStateWithSort & {
    selectedFilter: SelectedOrderByOption
  }
  selectedGroupFilter: string
}

const AssignmentSubmissionTabContext =
  createContext<AssignmentSubmissionTabContextValue | null>(null)

export function useAssignmentSubmissionTabContext() {
  return useContext(AssignmentSubmissionTabContext)
}

interface Props {
  children: ReactNode
}

export default function AssignmentSubmissionTabProvider({ children }: Props) {
  const drawer = useContentUsageDrawer()

  const [selectedGroupFilter, setSelectedGroupFilter] =
    useState<MemberGroupFilterOption["value"]>("all")
  const [toolbarState, setToolbarState] = useState<
    DiscoTableToolbarStateWithSort & {
      selectedFilter: SelectedOrderByOption
    }
  >({
    search: "",
    sort: {
      id: "none",
      order: { field: "createdAt" },
      title: "Sort",
    },
    direction: "ASC",
    selectedFilter: ORDER_BY_OPTIONS[0],
  })

  function handleSearchChange(value: string) {
    setToolbarState({
      ...toolbarState,
      search: value,
    })
  }

  function handleOrderBySelect(sortOption: DiscoTableSortOption) {
    if (sortOption)
      setToolbarState({
        ...toolbarState,
        sort: {
          id: sortOption.value,
          order: sortOption.context!.order,
          title: sortOption.title,
        },
        direction: toolbarState.direction === "DESC" ? "ASC" : "DESC",
      })
  }

  function closeDetailsView() {
    drawer.selectSubmission("")
  }

  function handleSelectGroupFilter(selectedValue: string | null) {
    if (selectedValue) {
      setSelectedGroupFilter(selectedValue)
    }
  }

  function mapSelectedFilterToInput() {
    const memberGroupFilterInput: AssignmentSubmissionMemberGroupFilterInput = {}

    if (selectedGroupFilter === "unassigned") {
      memberGroupFilterInput.unassigned = true
    } else if (selectedGroupFilter !== "all") {
      memberGroupFilterInput.memberGroupId = selectedGroupFilter
    }
    return memberGroupFilterInput
  }

  return (
    <AssignmentSubmissionTabContext.Provider
      value={{
        handleSearchChange,
        handleOrderBySelect,
        closeDetailsView,
        handleSelectGroupFilter,
        toolbarState,
        mapSelectedFilterToInput,
        selectedGroupFilter,
      }}
    >
      {children}
    </AssignmentSubmissionTabContext.Provider>
  )
}
